header,
footer .footer-nav,
.footer-legal,
button,
.ariane,
.slick-dots,
.tab-control,
.slider-nav-link,
.exergue-deco,
.paragraph--type--video,
.paragraph--type--slider,
.paragraph--type--iframe,
form,
.fancybox__container,
footer,
.leaflet-control-container,
.page-top-actions,
.figure-deco,
.paragraph--type--related,
.tools,
.side-links-list,
.contact-deco {
  display: none !important;
}

a:not([data-fancybox]) {
  &::after {
    content: ' ( ' attr(href) ' ) ';
  }
}

.paragraph {
  figure {
    margin-left: 0;
  }

  img {
    max-width: 50vw;
  }
}

.slick-slide {
  left: auto !important;
  top: auto !important;
  opacity: 1 !important;
}

table {
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  text-align: center;
  font-weight: 400;
  border-spacing: 0;

  tr {
    th {
      border-bottom: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      font-weight: 700;
      padding: 1.3rem 0.7rem;
    }

    td {
      border-bottom: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      padding: 1.3rem 0.7rem;

      &:first-of-type {
        font-weight: 700;
      }
    }
  }
}

h1 {
  font-size: 2em;
}

[data-aos^=fade] {
  opacity: 1 !important;
  transform: none !important;
}

.accordeon-content {
  height: auto !important;
}

#map {
  height: 400px;
  width: 700px;
}

img {
  max-width: 100%;
}
